import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';


export default function Dashboard() {

  const [status_list,setStatusList]=useState([])
  const getStatus = async ()=> {
    const data = new FormData();
    data.append('inquiry_id','');
 
            axios({
              method: 'post',
              url: '/api_lock/status/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               

                 console.log(s.data)
                 setStatusList(s.data)
               
                
              
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

    const [user,setUser]=useState(null);
  useEffect(() => {
    const a=localStorage.getItem("Manager");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      getStatus();
      const pin=localStorage.getItem("PIN")
      if(pin=="")
      {
        window.location.replace("/pin")
      }

      setUser(a)
      User_by(JSON.parse(a)[0].id); 
    }

    
  }, []);

  const add_pin = async (uid)=> {
    
    const data = new FormData();
   
    data.append('uid',uid );
    data.append('s', "Out");
   
        axios({
          method: 'post',
          url: '/api_lock/user/pin_add.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
             console.log("run",s.data);
             localStorage.setItem("PIN","") 
             window.location.replace("/pin")
            
     
          })
          .catch(s => {
            // console.log('here catch =>>', s);
          });
      };

  const User_by = async (id)=> {
    const data = new FormData();
    data.append('uid',id );
        axios({
          method: 'post',
          url: '/api_lock/user/uid_block.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
             console.log("run",s.data);

          if(s.data[0].msg=="false")
          {
            localStorage.clear()
                    window.location.replace("/login")
          }
            
     
          })
          .catch(s => {
            // console.log('here catch =>>', s);
          });
      };

  return (
  <>
  <>
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <title>Manager Dashboard </title>
  <div className="app">
    <div className="layout">
      {/* Header START */}
      <div className="header">
        <div className="logo logo-dark">
          <a href="index.html">
            <img src="assets/images/logo/logo.png" alt="Logo" />
            <img
              className="logo-fold"
              src="assets/images/logo/logo-fold.png"
              alt="Logo"
            />
          </a>
        </div>
        <div className="logo logo-white">
          <a href="index.html">
            <img src="assets/images/logo/logo-white.png" alt="Logo" />
            <img
              className="logo-fold"
              src="assets/images/logo/logo-fold-white.png"
              alt="Logo"
            />
          </a>
        </div>
        <div className="nav-wrap">
          <ul className="nav-left">
            <li className="desktop-toggle">
              <a href="javascript:void(0);">
                <i className="anticon" />
              </a>
            </li>
            <li className="mobile-toggle">
              <a href="javascript:void(0);">
                <i className="anticon" />
              </a>
            </li>
            <li>
              <p style={{margin:"30px"}}>Name : {user && JSON.parse(user)[0].name}</p>
            </li>
            <li>
              <p style={{margin:"30px"}}>Email : {user && JSON.parse(user)[0].username}</p>
            </li>
            <li>
              <p style={{margin:"30px"}}>Account : {user && JSON.parse(user)[0].type}</p>
            </li>
          </ul>
          <ul className="nav-right">
            
            <li className="dropdown dropdown-animated scale-left">
              <div className="pointer" data-toggle="dropdown">
                <div className="avatar avatar-image  m-h-10 m-r-15">
                <i class="anticon anticon-down" style={{color:"#054089"}}></i>
                </div>
              </div>

              <div className="p-b-15 p-t-20 dropdown-menu pop-profile">
                <div className="p-h-20 p-b-15 m-b-10 border-bottom">
                  <div className="d-flex m-r-50">
                    <div className="avatar avatar-lg avatar-image">
                    <i class="anticon anticon-down" style={{color:"#054089"}}></i>
                    </div>
                    <div className="m-l-10">
                      <p className="m-b-0 text-dark font-weight-semibold">
                      {user==null?<></>:JSON.parse(user)[0].name}
                      </p>
                      <p className="m-b-0 opacity-07"> {user==null?<></>:JSON.parse(user)[0].name}</p>
                    </div>
                  </div>
                </div>
                
                
                <a style={{textDecoration:"none"}} href='/announcements?type=status' >
                <p
                   
                  className="dropdown-item d-block p-h-15 p-v-10">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <i className="anticon opacity-04 font-size-16 anticon-logout" />
                      <span className="m-l-10">Status Policy</span>
                    </div>
                    <i className="anticon font-size-10 anticon-right" />
                  </div>
                </p>
                </a>
                <a style={{textDecoration:"none"}} href='/announcements?type=other' >
                <p
                   
                  className="dropdown-item d-block p-h-15 p-v-10">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <i className="anticon opacity-04 font-size-16 anticon-logout" />
                      <span className="m-l-10">Company Policy</span>
                    </div>
                    <i className="anticon font-size-10 anticon-right" />
                  </div>
                </p>
                </a>
                {/* <a style={{textDecoration:"none"}} href='/dup_manager' >
                <p
                   
                  className="dropdown-item d-block p-h-15 p-v-10">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <i className="anticon opacity-04 font-size-16 anticon-logout" />
                      <span className="m-l-10">Search Phone no.</span>
                    </div>
                    <i className="anticon font-size-10 anticon-right" />
                  </div>
                </p>
                </a> */}
                <p
                   onClick={()=>{
                    localStorage.clear()
                    window.location.replace("/login")
                    
                    }}
                  className="dropdown-item d-block p-h-15 p-v-10">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <i className="anticon opacity-04 font-size-16 anticon-logout" />
                      <span className="m-l-10">Logout</span>
                    </div>
                    <i className="anticon font-size-10 anticon-right" />
                  </div>
                </p>
              </div>
            </li>
            <li>
              <a
                 onClick={()=>{
                  add_pin(JSON.parse(user)[0].id)
                }}
                
              >
               <i class="anticon anticon-logout"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* Header END */}
      {/* Side Nav START */}
     

<div className="side-nav">
  <div className="side-nav-inner">
    <ul
      className="side-nav-menu scrollable ps-container ps-theme-default"
      data-ps-id="e978f676-4d08-b2bd-3b7b-66f20b752c4e">
    

      <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/manager_main">
          <span className="icon-holder">
            <i className="anticon anticon-dashboard" />
          </span>
          <span className="title" >Dashboard</span>
        </a>
      </li>

     

      <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/pending_view">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Pending for view</span>
        </a>
      </li>

      <li  className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/wait_for_response_manager">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Waiting Response</span>
        </a>
      </li>

      <li  className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/res_manager">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Manager Response</span>
        </a>
      </li>

      {
  status_list.map((v,i)=>(
    <>
          {
              v.manager==1?<>
              <li className="nav-item dropdown">
          <a style={{textDecoration:"none"}} href={"/status_view_manager?status="+v.value+"&name="+encodeURIComponent(v.name)}>
            <span className="icon-holder">
            <i className="anticon anticon-pie-chart" />
            </span>
            <span className="title" style={{fontSize:'13px',color:"green"}}>{v.name}</span>
          </a>
        </li>
        </>:<></>
            }


    </>
  ))
}

      {/* <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/voicemail_manager">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Voicemail</span>
        </a>
      </li> */}




      {/* <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/follow_manager">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Follow Up List</span>
        </a>
      </li> */}

      <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/team_inq?d=1">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Date To Date Report</span>
        </a>
      </li>



      {/* <li className="nav-item dropdown">
        <a href="/agent_inq">
          <span className="icon-holder">
          <i class="anticon anticon-team"></i>
          </span>
          <span className="title">Agent</span>
        </a>
      </li> */}

      <li className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/manager_team">
          <span className="icon-holder">
          <i class="anticon anticon-team"></i>
          </span>
          <span className="title">Agents Lists </span>
        </a>
      </li>
      {/* <li  className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/reminder_manager">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Reminder List</span>
        </a>
      </li> */}
      <li  className="nav-item dropdown">
        <a style={{textDecoration:"none"}} href="/all_complaint_show">
          <span className="icon-holder">
          <i className="anticon anticon-pie-chart" />
          </span>
          <span className="title">Complaint List</span>
        </a>
      </li>
      
      
      
    </ul>
  </div>
</div>

      {/* Side Nav END */}
    
      
      
    </div>
  </div>
</>

  </>
  );
}