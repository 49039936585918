import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav_manager";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";

export default function Dashboard() {
    const text_th = {
        color: 'Black',
        fontSize: '14px',
       
      };
      const text_td = {
        color: 'orange',
        fontSize: '14px',
        fontWeight: 'bold',
        width: 'fit-content'
       
      };

  const [list,setList]=useState([])
  const [name,setName]=useState("")
  const [type,setType]=useState("")
  const [msg,setMsg]=useState("")
  const [dataCall,setDataCall]=useState("")
  const getphone = async (p) => {
    try {
      const data = new FormData();
      data.append('phone', p);
  
      const response = await axios({
        method: 'post',
        url: '/api_lock/api_calls/getphone.php',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'},
      });
  
      console.log("apicalls", response.data);
      setDataCall(response.data)
         // Returning the data retrieved from the API
    } catch (error) {
      console.error('Error occurred in API call:', error);
      throw error;  // Re-throwing the error for handling at the caller's level if needed
    }
  };

  const [status_list,setStatusList]=useState([])
  const getStatus = async ()=> {
    const data = new FormData();
    data.append('inquiry_id','');
 
            axios({
              method: 'post',
              url: '/api_lock/status/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               

                 console.log(s.data)
                 setStatusList(s.data)
               
                
              
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

                     // Function to get names by value
                     const getNamesByValue = (array, value) => {
                      return array
                        .filter(item => item.value === value)
                        .map(item => item.name);
                    };

                      const [user,setUser]=useState(null);
  useEffect(() => {
    const a=localStorage.getItem("Manager");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      setUser(a)
      


     
    }


  
  
  }, []);

  useEffect(() => {
    getStatus();
    const queryParams = new URLSearchParams(window.location.search)
    const type = queryParams.get("type")
    const value = queryParams.get("value")
    if(type==null || type=='')
    {
      
    }else if(value==null || value=='')
    {

    }else{

      if(type=='phone')
      {
        getphone(value)
      }

      setName(value)
      setType(type)
      get_Data(value,type)
    }



 



  }, []);


  const get_Data = async (name,type)=> {
    setMsg("")

const data = new FormData();

    data.append('value',name);

    data.append('type',type);

        axios({
          method: 'post',
          url: '/api_lock/admin/get_dup.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            const v=s.data;

            if(v.length>0)
            {
                

             setList(s.data)
             console.log("value ",s.data)

            }else{
                alert("NO Data...")
            }

            console.log('response =>>', s);

          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
      


  return (
  <>

  <Nav />

    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
<div className='card' style={{padding:"20px"}} >
  <h2 style={{padding:"1px"}}>Duplicate By</h2>

<div className='row'>
    <div className='col-6'>
    <div class="form-group">
        <label for="formGroupExampleInput">Email Or Phone</label>
        <input type="text"  value={name} onChange={(v)=>{ setName(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Email / Phone"/>
    </div>
    </div>
    <div className='col-2'>
    <div class="form-group">
        <label for="formGroupExampleInput">Type</label>
        <select  value={type} onChange={(v)=>{ setType(v.target.value)}} className="form-select" aria-label="Default select example">
  <option value={""}>Select</option>
  
  <option>email</option>
  <option>phone</option>
 

</select>
    </div>

  
    </div>
{/* {
  JSON.parse(localStorage.getItem("Access"))[0].a10==true?"true":"false"} */}

</div>
{
    msg==""?<></>:<div className="alert alert-danger">
    {msg}
</div>
}
<div className='row'>
    <div className='col-6'>
    <button onClick={()=>{

     if(name=="" )
          {
              setMsg("Type Email / ip / Phone")
          }else if(type=="")
          {
              setMsg("Select Type")
          }else{
            get_Data(name,type);
            if(type=='phone')
              {
                getphone(name)
              }
          }
    }} className="btn btn-primary m-r-5">GET</button>
    </div>

</div>



</div>

       

           <div className='card' style={{padding:"10px"}} >
               
           <h3>Call History</h3>
           
           {
  dataCall==""?<h2 style={{color:'red'}}>loading</h2>:dataCall.status!="success"?<>Failed </>:<>
  {
dataCall.result.map((v,i)=>(
  <div className='card' style={{padding:"10px"}}>
  <div className='row' style={{margin:'2px'}}>
    <table>
      <tr>
        <td>DID</td>
        <td style={{color:'red'}}>{v.DID}</td>
       
        <td>Date</td>
        <td style={{color:'red'}}>{v.Date} </td>
        <td>Time</td>
        <td style={{color:'red'}}>{v.Time}</td>
        
    
      </tr>
      <tr>
        
       
      <td>Title</td>
        <td style={{color:'red'}}>{v.Title}</td>

        <td>Duration</td>
        <td style={{color:'red'}}>{v.Duration}</td>
       
        {/* <td>RingTime</td>
        <td style={{color:'red'}}>{v.RingTime}  </td> */}
        <td>Type</td>
        <td> <span style={{color:'blue'}}> {v.Type}</span> </td>
        
    
      </tr>
    </table>

              
                </div>
                </div>
))          
  }
  </>
  

}
  
      
    

      
         </div>
      
          
          

  

 <h3>Received Inquiry Total: {
    list.length
 }
 </h3>
<div className='row'>
        
          
        <div className='col-12'>
        <div className="card">


<div className="card-body">
<div>
  <div >
  
    <div className="row">
      <div >
      
  {
    list.map((v)=>(   
    

        <div >
            
        <h4>Received Inquiry</h4>

        <div className='row'>
            <div className='col-6'>
            <div className='row'>
        <div className='col-6' style={text_th} >Inquiry ID</div>
        <div className='col-6' style={text_td}>{v.id}</div>
      
        </div>
     <div className='row'>
   
        <div className='col-6' style={text_th} >Web</div>
        <div className='col-6' style={text_td}>{v.web}</div>
        </div>

     <div className='row'>
        <div className='col-6' style={text_th} >Inquiry Date</div>
        <div className='col-6' style={text_td}>{v.date_portal} {v.date_time}</div>
      
        </div>
     <div className='row'>
   
        <div className='col-6' style={text_th} >Inquiry Received</div>
        <div className='col-6' style={text_td}>{v.sender}</div>
        </div>

     <div className='row'>
        <div className='col-6' style={text_th} >Name</div>
        <div className='col-6' style={text_td}>{v.name}</div>

        </div>
     <div className='row'>

        <div className='col-6' style={text_th} >Email</div>
        <div className='col-6' style={text_td}>{v.email}</div>
        </div>

     <div className='row'>
        <div className='col-6' style={text_th} >Phone </div>
        <div className='col-6' style={text_td}>{v.phone}</div>
        <div className='col-6' style={text_th} >Departure</div>
        <div className='col-6' style={text_td}>{v.dep}</div>
        </div>
     <div className='row'>
        <div className='col-6' style={text_th} >Arrival</div>
        <div className='col-6' style={text_td}> {v.arri}</div>
        <div className='col-6' style={text_th} >Tour Date</div>
        <div className='col-6' style={text_td}>{v.date}</div>
        </div>
     <div className='row'>
        <div className='col-6' style={text_th} >Makkah</div>
        <div className='col-6' style={text_td}>{v.makkah}</div>
        <div className='col-6' style={text_th} >Madina</div>
        <div className='col-6' style={text_td}>{v.madina}</div>
        </div>
     <div className='row'>
        <div className='col-6' style={text_th} >Rooms</div>
        <div className='col-6' style={text_td}>{v.rooms}</div>
        <div className='col-6' style={text_th} >Accommodation</div>
        <div className='col-6' style={text_td}>{v.acc}</div>
        {/* <div className='col-6' style={text_th} >Type</div>
        <div className='col-6' style={text_td}>{v.type}</div> */}
        </div>

     <div className='row'>
       
     {/* view_response_id.php */}
        {
            v.duration==""?<></>:<> <div className='col-6' style={text_th} >Duration</div>
            <div className='col-3' style={text_td}>{v.duration}</div></>
        }
       
        </div>
            </div>
            <div className='col-6'>
                <h5>{v.agent_name}</h5>
                <div className='row'>
                            <div className='col-3'>Status</div>
                            <div className='col-7'>Msg</div>
                            <div className='col-2'>Create at</div>
                            
                </div>

                
                {
                    

                    v.res.map((c)=>(
                        <>
                        <div className='row'>
                            
                            <div className='col-3'> { getNamesByValue(status_list,c.status)} {c.r_date} </div>
                            <div className='col-7'>{c.msg}</div>
                            
                            <div className='col-2'>{c.at}</div>
                           
                        </div>
                         </>
                    ))
                }
            </div>

        </div>
        
        

      </div>
   
       
       

  ))
  }
    
      </div>
    </div>
    
  </div>
</div>

</div>
  <div >


  
    
    
  </div>
  

</div>
        </div>
    </div>
        </div>
       
 
       

    
      

       
    
   
<Footer />
  
      </div>
  </>
  );
}