import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav_manager";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

  const [list,setList]=useState([])
  const [listold,setListold]=useState([])
  const [listoldStatus,setListoldStatus]=useState(false)
  const [user,setUser]=useState()
  const [days,setDays]=useState("");
  const [d1,setD1]=useState(null);
  const [d2,setD2]=useState(null);

  const [selected,setSelected]=useState(null)
  const [agentList,setAgentList]=useState([])

  const [selected_website,setSelected_website]=useState(null)
  const [WebsiteList,setWebsiteList]=useState([])
  const [f1,setf1]=useState("")
  const [f2,setf2]=useState("")
  const [f3,setf3]=useState("")
  const [f4,setf4]=useState("")


  useEffect(() => {
    getStatus();
    const a=localStorage.getItem("Manager");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      setUser(a)
      TeamList("Agent",JSON.parse(a)[0].team)
      webList()
  
    }
  
  
  }, []);


  const TeamList = async (typ,id)=> {
    const data = new FormData();
        data.append('team_id',id);
        data.append('type',typ);
            axios({
              method: 'post',
              url: '/api_lock/manager/team_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
    
                 if(typ=="Agent"){

                    setAgentList(s.data)
                }
    
           console.log(s.data)
    
    
    
                 
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const webList = async ()=> {
    const data = new FormData();
     
            axios({
              method: 'post',
              url: '/api_lock/web/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setWebsiteList(s.data)

              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getInq = async (id)=> {
    const data = new FormData();
    data.append('team_id',id);
    data.append('d1',d1);
    data.append('d2',d2);
            axios({
              method: 'post',
              url: '/api_lock/manager/by_team_inq.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  // Function to count the items based on status
  const getStatusCount = status => {
    return list.filter(item => item.agent_status === status).length;
  }

  const getfilter = status => {
    const a=list;
    setf1(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.agent_status === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.agent_status === status);
      setList(newArray)


      console.log("array ",newArray)

    }

  
    
  }

  const getfilter_type = status => {
    const a=list;
    setf2(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.sender_type === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.sender_type === status);
      setList(newArray)
      console.log("array ",newArray)
    }
  }

  const getfilter_website = status => {
    const a=list;
    setf3(status)
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.web === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.web === status);
      setList(newArray)
      console.log("array ",newArray)
    }
    
  }

  const getfilter_Agent = (status,name) => {
    setf4(name)
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.agent_id === status);
      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)
      const newArray = a.filter(item => item.agent_id === status);
      setList(newArray)
      console.log("array ",newArray)

    }

  
    
  }

  const getfilter_dail = () => {
    
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.manager_response >0)

      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.manager_response >0 );

      setList(newArray)

      console.log("array ",newArray)

    }  
  }
  const getfilter_none_dial = () => {
    
    const a=list;
    if(listoldStatus)
    {
      const newArray = a.filter(item => item.manager_response==0);

      setList(newArray)
      console.log("array s ",newArray)
    }else{
      setListold(a)
      setListoldStatus(true)

      const newArray = a.filter(item => item.manager_response==0);

      setList(newArray)

      console.log("array ",newArray)

    }  
  }

  const getTypeCount = status => {
    return list.filter(item => item.sender_type === status).length;
  }


  const getresponseCount = () => {
    return list.filter(item => item.manager_response == 0 ).length;
  }

  const resetAll = () => {
    const a=listold;
    setList(a)
    setListoldStatus(false)
    setSelected()
    setSelected_website()
    setf1("")
    setf2("")
    setf3("")
    setf4("")
  }

  

  const [status_list,setStatusList]=useState([])
  const getStatus = async ()=> {
    const data = new FormData();
    data.append('inquiry_id','');
 
            axios({
              method: 'post',
              url: '/api_lock/status/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               

                 console.log(s.data)
                 setStatusList(s.data)
               
                
              
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };
          
           // Function to get names by value
           const getNamesByValue = (array, value) => {
            return array
              .filter(item => item.value === value)
              .map(item => item.name);
          };




  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
         
        <div className='card' style={{padding:"20px"}} >
<h2>Inquirers</h2>
<div className='row'>

<div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date Start</label>
<input type="date"  value={d1} onChange={(v)=>{ setD1(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>

<div className='col-3'>
<div class="form-group">
<label for="formGroupExampleInput">Date End</label>
<input type="date"  value={d2} onChange={(v)=>{ setD2(v.target.value)}} class="form-control" id="formGroupExampleInput" placeholder="Date"/>
</div>
</div>

<div className='row'>
<div className='col-3'>
<button onClick={()=>{

if(d1===null)
{
alert("Select Date")
}else if(d2===null)
{
alert("End Date")
}else{

  if(user=="")
  {

  }else{
    setList([])
    setSelected_website()
    setSelected()
    getInq(JSON.parse(user)[0].team)
  }

}
}}  className="btn btn-primary m-r-5">Get</button>

</div>

</div>

</div>

</div>
<h5> Total Inquires: {list.length}       {f1!=""?<> {f1} / </>:<></>}
          {f2!=""?<>{f2} / </>:<></>}
          {f3!=""?<>{f3} / </>:<></>}
          {f4!=""?<>{f4} / </>:<></>}  </h5>

     
{
  listoldStatus?        <button style={{margin:"10px"}} onClick={()=>{
    resetAll()
  }}  class="btn btn-success m-r-5">Reset</button>:""
}

<div className="row" style={{ margin: '0 -1px' }}>
  {/* 1  start*/}
        <div
       
         
          className="col-lg-3
 col-md-4 col-sm-6"
          style={{ padding: '3px' }}>
          <div
            className="card"
            style={{
              border: '3px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '8px',
              textAlign: 'center',
              backgroundColor: 'white',
            }}
            onClick={()=>{
              getfilter_dail()
            }}

            
          >
            <div className="card-body"
              style={{ padding: '5px' }}>
              <div
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <p
                    className="m-b-0"
                    style={{
                      marginBottom: '3px',
                      color: '#202020',
                    }}
                  >Manager Redialed</p>
                  <h4
                    style={{
                      fontSize: '1.5rem',
                      color: '#029E9D',
                    }}
                  >
                    <span>{list.length-getresponseCount()}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 1  end*/}

                {/* 2  start*/}
                <div
       
         
       className="col-lg-3
col-md-4 col-sm-6"
       style={{ padding: '3px' }}>
       <div
         className="card"
         style={{
           border: '3px solid #ddd',
           borderRadius: '8px',
           boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
           padding: '8px',
           textAlign: 'center',
           backgroundColor: 'white',
         }}

         onClick={()=>{
            getfilter_none_dial()
          }}

         
       >
         <div className="card-body"
           style={{ padding: '5px' }}>
           <div
             className="d-flex justify-content-between align-items-center"
           >
             <div>
               <p
                 className="m-b-0"
                 style={{
                   marginBottom: '3px',
                   color: '#202020',
                 }}
               >Manager Pending Redial</p>
               <h4
                 style={{
                   fontSize: '1.5rem',
                   color: '#029E9D',
                 }}
               >
                 <span>{getresponseCount()}</span>
               </h4>
             </div>
           </div>
         </div>
       </div>
     </div>
     {/* 2  end*/}


                     {/* 3  start*/}
                     <div
       
         
       className="col-lg-3
col-md-4 col-sm-6"
       style={{ padding: '3px' }}>
       <div
         className="card"
         style={{
           border: '3px solid #ddd',
           borderRadius: '8px',
           boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
           padding: '8px',
           textAlign: 'center',
           backgroundColor: 'white',
         }}
         onClick={(()=>(
          getfilter_type("Admin")
         ))}

         
       >
         <div className="card-body"
           style={{ padding: '5px' }}>
           <div className="d-flex justify-content-between align-items-center">
             <div>
               <p
                 className="m-b-0"
                 style={{
                   marginBottom: '3px',
                   color: '#202020',
                 }}>Inquire Send by Admin</p>
               <h4
                 style={{
                   fontSize: '1.5rem',
                   color: '#029E9D',
                 }}
               >
                 <span>{getTypeCount("Admin")}</span>
               </h4>
             </div>
           </div>
         </div>
       </div>
     </div>
     {/* 3  end*/}


                     {/* 4  start*/}
                     <div
       
         
       className="col-lg-3
col-md-4 col-sm-6"
       style={{ padding: '3px' }}>
       <div
         className="card"
         style={{
           border: '3px solid #ddd',
           borderRadius: '8px',
           boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
           padding: '8px',
           textAlign: 'center',
           backgroundColor: 'white',
         }}

         onClick={(()=>(
          getfilter_type("Manager")
         ))}

         
       >
         <div className="card-body"
           style={{ padding: '5px' }}>
           <div
             className="d-flex justify-content-between align-items-center"
           >
             <div>
               <p
                 className="m-b-0"
                 style={{
                   marginBottom: '3px',
                   color: '#202020',
                 }}>Inquire Send by Manager</p>
               <h4
                 style={{
                   fontSize: '1.5rem',
                   color: '#029E9D',
                 }}
               >
                 <span>{getTypeCount("Manager")}</span>
               </h4>
             </div>
           </div>
         </div>
       </div>
     </div>
     {/* 4  end*/}


  {status_list.map(
    (v, i) =>
      i > 0 && (
        <div
       
          key={i}
          className="col-lg-3
 col-md-4 col-sm-6"
          style={{ padding: '3px' }}>
          <div
            className="card"
            style={{
              border: '3px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '8px',
              textAlign: 'center',
              backgroundColor: 'white',
            }}

            onClick={()=>(
              getfilter(v.value)
            )}
          >
            <div className="card-body"
              style={{ padding: '5px' }}>
              <div
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <p
                    className="m-b-0"
                    style={{
                      marginBottom: '3px',
                      color: '#202020',
                    }}
                  >
                    {v.name}
                  </p>
                  <h4
                    style={{
                      fontSize: '1.5rem',
                      color: '#029E9D',
                    }}
                  >
                    <span>{getStatusCount(v.value)}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  )}
</div>


    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   
   <div className='row'>

    <div className='col-3'>
<h5>Date to Date</h5>
      </div>
    <div className='col-3'>
<h5>By Agent</h5>
</div>
    <div className='col-3'>
<h5>By Web</h5>
     
      </div>
      
      </div>


   <div className='row'>

    <div className='col-3'>
       <h4 style={{color:"green"}} >{d1} to {d2} </h4>
    </div>
    <div className='col-3'>
    <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"
       value={selected}
        onChange={(event, newValue) => {
            console.log(newValue)
            setSelected(newValue)  
            if(newValue===null)
            {
              resetAll()
              
            }else{
              getfilter_Agent(newValue.id,newValue.name)
              

            }

        }}
      options={agentList}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.name} 
        </Box>
      )}
      renderInput={(params) => <TextField {...params}  label="Agent Name" />}
    />
    </div>
    <div className='col-4'>
    <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"
       value={selected_website}
        onChange={(event, newValue) => {
            console.log(newValue)
            setSelected_website(newValue)
            if(newValue===null)
            {
              resetAll()
              
            }else{
              getfilter_website(newValue.web)
              //getfilter_Agent

            }
            
        }}
      options={WebsiteList}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.web}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.web}
        </Box>
      )}
      renderInput={(params) => <TextField {...params}  label="web" />}
    />
    </div>
   

   </div>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
              <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                
                 
                  <th
                    className="sorting"
          
                  >
                    Agent Name
                  </th>
                  <th
                    className="sorting"
                   
          
                  >
                    Received
                  </th>
                  <th
                    className="sorting">
                    Status
                  </th>
                  <th
                    className="sorting">
                    Send by
                  </th>
                  <th
                    className="sorting">
                    Responsed
                  </th>
                  <th
                    className="sorting">
                   Action
                  </th>
                  
                </tr>
              </thead>
              <tbody>
      {
        list.map((v,i)=>(   
        

              <>
                <tr role="row" className="odd">
              
                  <td>{v.id} </td>
                  <td style={{color:v.manager_response==0?"red":"green"}}> {v.web}</td>
                  
                  
                  <td>{v.agent_name}</td>
                  <td>{v.sender_time} </td>
                  <td>{getNamesByValue(status_list,v.agent_status)}</td>
              
                
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <div >
<center>

      
           
          

 
           <div className='row'>
            <div className='col-3'>Name</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.name}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Email</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.email}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Phone</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.email}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Dep</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.dep}</b></div>
           </div>

           <div className='row'>
            <div className='col-3'>Arri</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.arri}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Mak</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.makkah}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Man</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.madina}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Type</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.type}</b></div>
           </div>
        
           <div className='row'>
            <div className='col-3'>Acc</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.acc}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_portal}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Time</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_time}</b></div>
           </div>
           <div className='row'>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><a target="_blank" href={v.url}>{v.url}</a></div>
           </div>
                
</center>
        

         
          </div>
        }
      >
        <Button>{v.sender_type}</Button>
      </HtmlTooltip>
      </td>
      <td>{v.response} </td>
      <td>
        <a href={"View_inquiry_manager?id="+v.id}>
      <button  className="btn btn-warning m-r-5">Info</button>
      
</a>
      </td>
   
   
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}