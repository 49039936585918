import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useState, useEffect } from "react";
import axios from 'axios';

const FAQ = () => {

  const [status_list,setStatusList]=useState([])
  const getStatus = async (t)=> {
    const data = new FormData();
    data.append('type',t);
 
            axios({
              method: 'post',
              url: '/api_lock/policy/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               

                 console.log(s.data)
                 setStatusList(s.data)
               
                
              
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };





          // 

          
            useEffect(() => {
              const queryParams = new URLSearchParams(window.location.search)
              const t = queryParams.get("type")

              getStatus(t);

            }, []);

 


  return (
    <div className="container my-5" style={{ padding: '20px', borderRadius: '8px', background: 'linear-gradient(to bottom, #f9f9f9, #e0e0e0)' }}>
    <h1 className="text-center mb-4" style={{ color: '#029E9D', fontWeight: 'bold',fontSize:'32px' }}>Announcements</h1>
    {status_list.length > 0 && (
      <div
        style={{
          marginBottom: '20px',
          padding: '15px',
          borderRadius: '8px',
          
          background: '#fdc70398',
          color: '#202020',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
        }}
      >
        <h4 style={{ margin: 0 }}>{status_list[0].heading}</h4>
      </div>
    )}
    <Accordion defaultActiveKey="0">
      {status_list.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header style={{ fontWeight: 'bold', color: '#029E9D' }}>{item.qu}</Accordion.Header>
          <Accordion.Body style={{ color: '#202020', lineHeight: '1.6' }}>{item.ans}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  </div>
  
  );
};

export default FAQ;
