import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "./footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {
  
 

  const getInq = async ()=> {
    const data = new FormData();
        
            axios({
              method: 'post',
              url: '/api_lock/inq/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)
                getstate();
                getInq_QA();
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getInq_QA = async ()=> {
    const data = new FormData();
        
            axios({
              method: 'post',
              url: '/api_lock/inq/list_qa.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setListQA(s.data)
                console.log("QA_Data",s.data)
            
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


          
         
  const getstate = async ()=> {
    const data = new FormData();
        
            axios({
              method: 'post',
              url: '/api_lock/inq/get_inq_state.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setstate(s.data)
                console.log(s.data)
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const SetTrash = async (id)=> {
    const data = new FormData();
    data.append('id',id);
    data.append('s',"trash");
        
            axios({
              method: 'post',
              url: '/api_lock/inq/move.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                getInq();
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const send_team = async (id,team_id,admin_id)=> {
    const data = new FormData();
    data.append('inquiry_id',id);
    data.append('team_id',team_id);
    data.append('admin_id',admin_id);
        
            axios({
              method: 'post',
              url: '/api_lock/send_inq/send_team.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => { 
                getInq()
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };
  const send_team_to_agent = async (inquiry_id,team_id,admin_id,agent_id)=> {

    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
    data.append('team_id',team_id);
    data.append('admin_id',admin_id);
    data.append('agent_id',agent_id);
        
            axios({
              method: 'post',
              url: '/api_lock/send_inq/send_team_with_agent.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => { 
                console.log(s.data)
                getInq()
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };
          
  const [list,setList]=useState([])
  const [listqa,setListQA]=useState([])
  const [state,setstate]=useState([])
  const [team,setTeam]=useState([])
  const [selected,setSelected  ]=useState(null)
  const [agentList,setAgentList]=useState([])
  const [user,setUser]=useState(null);
  const [userAccess,setUserAccess]=useState([{a1:false,a2:false,a3:false,a4:false,a5:false,a6:false,a7:false,a8:false,a9:false,a10:false,a11:false,a12:false,a13:false,a14:false,a15:false,a16:false,a17:false,a18:false,}]);
 
  useEffect(() => {
    const a=localStorage.getItem("Admin");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      setUser(a)
      getInq();
      TeamList("Agent")
      AllTeamList();
      
      const z=JSON.parse(a)
      if(z[0].id==1)
        {
          
          localStorage.setItem("Access",JSON.stringify([{"a1":true,"a2":true,"a3":true,"a4":true,"a5":true,"a6":true,"a7":true,"a8":true,"a9":true,"a10":true,"a11":true,"a12":true,"a13":true,"a14":true,"a15":true,"a16":true,"a17":true,"a18":true,"a19":true,"a20":true,"a21":true,"a22":true,"a23":true,"a24":true,"a25":true,"a26":true,"a27":true,"a28":true,"a29":true,"a30":true,"a31":true,"a32":true,"a33":true}]))
          setUserAccess([{"a1":true,"a2":true,"a3":true,"a4":true,"a5":true,"a6":true,"a7":true,"a8":true,"a9":true,"a10":true,"a11":true,"a12":true,"a13":true,"a14":true,"a15":true,"a16":true,"a17":true,"a18":true,"a19":true,"a20":true,"a21":true,"a22":true,"a23":true,"a24":true,"a25":true,"a26":true,"a27":true,"a28":true,"a29":true,"a30":true,"a31":true,"a32":true,"a33":true}])
          
        }else{
        User_by(z[0].id);
       
        }

    }
  
  }, []);

  const User_by = async (id)=> {
    const data = new FormData(); 
    data.append('uid',id );
        axios({
          method: 'post',
          url: '/api_lock/user/uid_block.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
             console.log("run",s.data);
  
          if(s.data[0].msg=="false")
          {
                localStorage.clear()
                window.location.replace("/login")
          }else{
            const k=s.data[0].ac;
            localStorage.setItem("Access",k) 
            setUserAccess(JSON.parse(k))
          }

          })
          .catch(s => {
            // console.log('here catch =>>', s);
          });
      }




  const AllTeamList = async ()=> {
    const data = new FormData();
        data.append('status',"login");
            axios({
              method: 'post',
              url: '/api_lock/team/active_list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setTeam(s.data)
             
    
                })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



  const TeamList = async (typ)=> {
    const data = new FormData();
        data.append('type',typ);
            axios({
              method: 'post',
              url: '/api_lock/user/user_list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
    
                 if(typ=="Agent"){

                    setAgentList(s.data)
                }
    
           console.log(s.data)
    
    
    
                 
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

          const [csvData, setCsvData] = useState([]);
          
          useEffect(() => {
            fetchCSVData();    // Fetch the CSV data when the component mounts
        }, []); // The empty array ensures that this effect runs only once, like componentDidMount

        const fetchCSVData = () => {
          const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSvWSAfgDgsENCS0x_qws1Xr9WMwRG_oqZL0QNYjqg0Afo8TLkDIECHSHbq9TSoGy3zXwCMhYlpqZh4/pub?output=csv'; // Replace with your Google Sheets CSV file URL
      
              axios.get(csvUrl)    // Use Axios to fetch the CSV data
                  .then((response) => {
                      const parsedCsvData = parseCSV(response.data);        // Parse the CSV data into an array of objects
                      setCsvData(parsedCsvData);        // Set the fetched data in the component's state
                      console.log(parsedCsvData);        // Now you can work with 'csvData' in your component's state.
                  })
                  .catch((error) => {
                      console.error('Error fetching CSV data:', error);
                  });
          }

          function parseCSV(csvText) {
            const rows = csvText.split(/\r?\n/);        // Use a regular expression to split the CSV text into rows while handling '\r'
            const headers = rows[0].split(',');        // Extract headers (assumes the first row is the header row)
            const data = [];        // Initialize an array to store the parsed data
            for (let i = 1; i < rows.length; i++) {
                const rowData = rows[i].split(',');          // Use the regular expression to split the row while handling '\r'
                const rowObject = {};
                for (let j = 0; j < headers.length; j++) {
                    rowObject[headers[j]] = rowData[j];
                }
                data.push(rowObject);
            }
            return data;
        }
    
        useEffect(() => {
          // Request permission for notifications
          Notification.requestPermission();
        }, []);
      
        const showNotification = () => {
          if (!('Notification' in window)) {
            alert('This browser does not support desktop notification');
            return;
          }
      
          if (Notification.permission === 'granted') {
            
            alert("aaaa")
            alert("aaaa")
          
            const notification = new Notification('Hello!', {
              body: 'This is a notification from React web app!',
            
            });
      
            notification.onclick = () => {
              console.log('Notification clicked');
            };
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
              if (permission === 'granted') {
                showNotification();
              }
            });
          }
        };

  return (
  <>
  
<Nav />
  
    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          <div className="row">
          


          <div style={{padding:"10px"}}>
            {/* {JSON.stringify(csvData)} */}
          </div>
            <div className="col-md-6 col-lg-2">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">All Pending  </p>
                      <h2 className="m-b-0">
                        <span>{state.length<1?"":<>{state[0].pending} </>}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i class="anticon anticon-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Fresh Pending </p>
                      <h2 className="m-b-0">
                        <span>{list.length}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i class="anticon anticon-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-2">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Redial(QA) Pending</p>
                      <h2 className="m-b-0">
                      <span>{state.length<1?"":<>{state[0].pending-list.length}</>}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-gold">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-2">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Today </p>
                      <h2 className="m-b-0">
                      <span>{state.length<1?"":<>{state[0].today}</>}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-cyan">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Yesterday </p>
                      <h2 className="m-b-0">
                      <span>{state.length<1?"":<>{state[0].yesterday}</>}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-2">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Avg </p>
                      <h2 className="m-b-0">
                      <span>{state.length<1?"":<>{state[0].avg}</>}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-red">
                      <i className="anticon anticon-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

         
          </div>
          
          {/* <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Latest Inquirers</h5>
                    <div>
                      <a
                        href="javascript:void(0);"
                        className="btn btn-sm btn-default"
                      >
                        View All
                      </a>
                    </div>
                  </div>
                  <div className="m-t-30">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Customer</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>#5331</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                  <div
                                    className="avatar avatar-image"
                                    style={{ height: 30, width: 30 }}
                                  >
                                    <img
                                      src="assets/images/avatars/thumb-1.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <h6 className="m-l-10 m-b-0">
                                    Erin Gonzales
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td>8 May 2019</td>
                            <td>$137.00</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span className="badge badge-success badge-dot m-r-10" />
                                <span>Approved</span>
                              </div>
                            </td>
                          </tr>
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div> */}
          

{
  userAccess[0].a1&&<>
  <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>Latest Entries  <span onClick={getInq}><i style={{color:"#fd9c0d",margin:'10px',fontSize:'30px'}}  className="anticon anticon-undo"></i></span>
   <span onClick={()=>{   window.location.replace("/alltrash");}}><i style={{color:"#0D6EFD",margin:'5px',fontSize:'30px'}}  className="anticon anticon-delete"></i></span>
   <span onClick={()=>{   window.location.replace("/admin_main_types");}}><i style={{color:"#202020",margin:'10px',fontSize:'30px'}}  className="anticon anticon-rocket"></i></span>
      </h4>
 </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info">

              <thead>
                <tr role="row">
                  
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  {/* <th
                    className="sorting"
          
                  >
                    Name
                  </th> */}
                  <th
                    className="sorting"
                  >
                    Email
                  </th>
                  <th
                    className="sorting">
                    Phone
                  </th><th
                    className="sorting"
                  >
                    Teams
                  </th>
                  <th
                    className="sorting"
                  >
                    Agent
                  </th>
                  <th
                    className="sorting"
                  >
                    Action
                  </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id}  </td>
                  <td><a target='blank__' href={"/dup?value="+v.ip+"&type=ip"}> <Button variant="contained" color={v.sip=="new"?"success":"error"}>{v.sip}</Button></a> {v.web} </td>
                  {/* <td>{v.name}</td> */}
                  <td><a target='blank__' href={"/dup?value="+v.email+"&type=email"}><Button variant="contained" color={v.se=="new"?"success":"error"}>{v.se}</Button></a> {v.email}</td>
                
                  
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <div >
<center>


           
           <div className='row'>
            <div className='col-3'>IP</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.ip}</b></div>
           </div>
           <div className='row'>
            <div className='col-12'>Ad/ch/In</div>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} >{v.adult==""?<></>:<b style={{color:"green"}}>{v.adult}</b>}{v.child==""?<></>:<b style={{color:"red"}}> {v.child}</b>}{v.infant==""?<></>:<b style={{color:"blue"}}> {v.infant}</b>}</div>
           </div>
           <div className='row'>
            <div className='col-3'>Name</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.name}</b></div>
           </div>

           <div className='row'>
            <div className='col-3'>Dep</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.dep}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Arri</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.arri}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Mak</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.makkah}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Man</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.madina}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Type</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.type}</b></div>
           </div>
        
           <div className='row'>
            <div className='col-3'>Acc</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.acc}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_portal}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Time</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_time}</b></div>
           </div>
            <div className='row'>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}}><a target="_blank" href={v.url}>{v.url}</a></div>
           </div>
                
</center>
</div>
        }
      ><a target='blank__'  href={"/dup?value="+v.phone+"&type=phone"}>
        <Button variant="contained"
         color={v.sp=="new"?"success":v.sp=="dup"?"error":v.sp=="new_agent"?"info":v.sp=="dup_agent"?"secondary":"warning"}
         
         >{v.sp}</Button></a> {v.phone}  </HtmlTooltip>
       </td>
   
                 
                  <td>
                    <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"
      value={selected}
        onChange={(event, newValue) => {
          
         
          if(newValue === null)
          {
            console.log(newValue)
          }else{
            console.log(v.id," = ",newValue.id," = ",JSON.parse(user)[0].id);
            send_team(v.id,newValue.id,JSON.parse(user)[0].id)
          }

        }}

      options={team}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.name}
        </Box>
      )}

      renderInput={(params) => <TextField {...params}  label="Name" />}
    />
                  
                  </td>

                  <td>  
                 
                  <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"

       value={selected}
        onChange={(event, newValue) => {
          
         
          if(newValue === null)
          {
            console.log(newValue)
          }else{
            console.log(newValue)
            console.log(v.id," = ",newValue.id,"  => ",newValue.team," = ",JSON.parse(user)[0].id);
            // (inquiry_id,team_id,admin_id,agent_id)
            send_team_to_agent(v.id,newValue.team,JSON.parse(user)[0].id,newValue.id)
          }

        }}

      options={agentList}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.name}
        </Box>
      )}

      renderInput={(params) => <TextField {...params}  label="Agent" />}
    />
                  
                  </td>

                  <td>

                  <button onClick={()=>{
                    SetTrash(v.id)
                  }} className="btn btn-icon btn-warning">
    <i className="anticon anticon-delete"></i>
</button> 
                
                 
                  </td>
                  <td> <a href={"view_inquiry_admin?id="+v.id}> <button  className="btn btn-warning m-r-5">Info</button></a></td>
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>

       

          </div>
        </div>
        
      </div>
    </div>
  
  </div>
    
      
    
    </div>
    {/* <div>
          <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSvWSAfgDgsENCS0x_qws1Xr9WMwRG_oqZL0QNYjqg0Afo8TLkDIECHSHbq9TSoGy3zXwCMhYlpqZh4/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
          
          </div> */}

          
    </>
}
    





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}