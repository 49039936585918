import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";

export default function Dashboard() {

    
        const text_th = {
          color: 'Black',
          fontSize: '16px',
         
        };
        const text_td = {
          color: '#006400',
          fontSize: '16px',
          fontWeight: 'bold',
          width: 'fit-content'
         
        };



        function getRemainingDays(targetDate) {
          const currentDate = new Date();
          const remainingTime = new Date(targetDate) - currentDate;
          
          // Convert remaining time from milliseconds to days
          const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
          
          return remainingDays;
      }
       
      const [manager_list_risponse,setManagerlist_risponse]=useState([])

      
      const manager_response_list = async (inquiry_id)=> {
        const data = new FormData();
        data.append('inquiry_id',inquiry_id);
      
                axios({
                  method: 'post',
                  url: '/api_lock/manager/manager_view_response_id.php',
                  data: data,
                  headers: {'Content-Type': 'multipart/form-data'},
                })
                  .then(s => {
                    setManagerlist_risponse(s.data)
    
                    console.log(" setManagerlist_risponse ",s.data)
                   
             
                  })
                  .catch(s => {
                    console.log('here catch =>>', s);
                  });
              };

              

  const getInq = async (inquiry_id,agent_id)=> {
    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
    data.append('agent_id',agent_id);
            axios({
              method: 'post',
              url: '/api_lock/agent/inq.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

                
 const arry=s.data;
 if(arry=="[]")
 {
  console.log("null")
 }else{
  setList(s.data)
                console.log(s.data)
                response_list(inquiry_id,agent_id)

 }


               })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const response_list = async (inquiry_id,agent_id)=> {
    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
    data.append('agent_id',agent_id);
            axios({
              method: 'post',
              url: '/api_lock/agent/list_risponse.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setlist_risponse(s.data)
                manager_response_list(inquiry_id)
                

                console.log(s.data)
               
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const setServer = async (inquiry_id,agent_id,status,date,msg)=> {
    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
    data.append('agent_id',agent_id);
    data.append('status',status);
    data.append('date',date);
    data.append('msg',msg);
            axios({
              method: 'post',
              url: '/api_lock/agent/inq_response.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               

                 console.log(s.data)
                 setstatus("")
                 setDate("")
                 setmsg("")
                 response_list(inquiry_id,agent_id)
                
              
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const [status_list,setStatusList]=useState([])
  const getStatus = async ()=> {
    const data = new FormData();
    data.append('inquiry_id','');
 
            axios({
              method: 'post',
              url: '/api_lock/status/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
               

                 console.log(s.data)
                 setStatusList(s.data)
               
                
              
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };
            // Function to get names by value
  const getNamesByValue = (array, value) => {
    return array
      .filter(item => item.value === value)
      .map(item => item.name);
  };


  const [list,setList]=useState([])
  const [list_risponse,setlist_risponse]=useState([])
  const [msg,setmsg]=useState("")
  const [status,setstatus]=useState("")
  const [date,setDate]=useState("")

  const [user,setUser]=useState(null);

  useEffect(() => {
    
    getStatus();

    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    if(id==null || id=='')
    {
      window.location.replace("/agent_main")
    }

    if(queryParams==null)
    {
      //window.location.replace("/agent_main")
    }


    const a=localStorage.getItem("Agent");
    if(a==null)
    {
      window.location.replace("/login")
    }else{
      setUser(a)
      getInq(id,JSON.parse(a)[0].id);
     
    }

    


  }, []);


  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
        <p style={{fontSize:"12px"}}>
          <span style={{color:"red"}}>Note:</span> If you select 'Any of Not Interested' option; this inquiry will be share with QA Department.</p>
            <div className='row'>
        <div className='col-6 card'>
            <div style={{padding:30}}>
        
  <div className="form-row">
   
    <div className="form-group col-md-12">
      <label htmlFor="inputEmail4">Status</label>
      
      
        <select value={status} style={{fontSize:"12px"}}  onChange={(v)=>{
            
            if(v.target.value==="")
            {

            }else{
                setstatus(v.target.value)
            }

        }}  className="form-control">


        {
          status_list.map((v)=>(
<option value={v.value}>{v.name}</option>
          ))
        }



        </select>
        
    </div>
    {
        status=="Reminder"?<> <div className="form-group col-md-6">
        <label htmlFor="inputPassword4">Reminder Date</label>
        <input
          type="date"
          className="form-control"
          value={date}
          onChange={(v)=>{
            setDate(v.target.value)
          }}
          />
      </div>
      </>:<></>
    }
   
  </div>
  <div className="form-group">
    <label htmlFor="inputAddress">Write a proper message</label>
    <textarea
      type="text"
      className="form-control"
      id="inputAddress"
      rows="8"
      value={msg}
      onChange={(v)=>{
        setmsg(v.target.value)
      }}
      placeholder="Description About Inquiry"
    >{msg}</textarea>
  </div>
  
 

<div className='row'>
  <div className='col-9'>
  <button onClick={()=>{

const queryParams = new URLSearchParams(window.location.search)
const id = queryParams.get("id")

if(status=="")
{
 alert("Select Status");
}else{

 if(status=="Reminder")
 {
   if(date==""|| msg=="")
   {
     alert("Select Date and Message !")
   }else{
     const a =getRemainingDays(date);
      
   if(a<=0)
   {
     alert("Date is wrong")
   }else{
     if(msg.length<6)
       {
         alert("write a proper message")
       }else{
         setServer(id,JSON.parse(user)[0].id,status,date,msg)
       }
   }
   }

 }else{
   const a =getRemainingDays(date);
      
   if(a<=0)
   {
     alert("Date is wrong")
   }else{
     if(msg.length<6)
     {
       alert("Write down a proper message below")
     }else{
       setServer(id,JSON.parse(user)[0].id,status,date,msg)
     }

     
   }
  
   

 }


}

}} className="btn btn-primary">
Response
</button>
  </div>
  
  <div className='col-3'>
<a href='/announcements?type=status'>
  <button className="btn btn-warning btn-sm  ">
  Status Policy
</button>
</a>

  </div>

</div>

</div>
        </div>
          
            <div className='col-6'>
            <div className="card">
  
  
   <div className="card-body">
 <div>
      <div >
      
        <div className="row">
          <div >
          
      {
        list.map((v)=>(   
        

              <div >
                
                <h1>Received Inquiry</h1>
                
                
             <div className='row'>
                <div className='col-6' style={text_th} >Inquiry ID</div>
                <div className='col-6' style={text_td}>{v.id}</div>
              
                </div>
             <div className='row'>
           
                <div className='col-6' style={text_th} >Web</div>
                <div className='col-6' style={text_td}>{v.web}</div>
                </div>

             <div className='row'>
                <div className='col-6' style={text_th} >Inquiry Date</div>
                <div className='col-6' style={text_td}>{v.date_portal} {v.date_time}</div>
              
                </div>
             <div className='row'>
           
                <div className='col-6' style={text_th} >Inquiry Received</div>
                <div className='col-6' style={text_td}>{v.rec_time}</div>
                </div>

             <div className='row'>
                <div className='col-6' style={text_th} >Name</div>
                <div className='col-6' style={text_td}>{v.name}</div>

                </div>
             <div className='row'>
       
                <div className='col-6' style={text_th} >Email</div>
                <div className='col-6' style={text_td}>{v.email}</div>
                </div>

             <div className='row'>
                <div className='col-6' style={text_th} >Phone </div>
                <div className='col-6' style={text_td}>{v.phone}</div>
                <div className='col-6' style={text_th} >Departure</div>
                <div className='col-6' style={text_td}>{v.dep}</div>
                </div>
             <div className='row'>
                <div className='col-6' style={text_th} >Arrival</div>
                <div className='col-6' style={text_td}> {v.arri}</div>
                <div className='col-6' style={text_th} >Tour Date</div>
                <div className='col-6' style={text_td}>{v.date}</div>
                </div>
             <div className='row'>
                <div className='col-6' style={text_th} >Makkah</div>
                <div className='col-6' style={text_td}>{v.makkah}</div>
                <div className='col-6' style={text_th} >Madina</div>
                <div className='col-6' style={text_td}>{v.madina}</div>
                </div>
             <div className='row'>
                <div className='col-6' style={text_th} >Rooms</div>
                <div className='col-6' style={text_td}>{v.rooms}</div>
                <div className='col-6' style={text_th} >Accommodation</div>
                <div className='col-6' style={text_td}>{v.acc}</div>
                </div>

             <div className='row'>
               
                <div className='col-6' style={text_th} >Sender Type</div>
                <div className='col-6' style={text_td}>{v.type_}</div>
                {
                  v.url==""?<></>:<>
                      <div className='col-6' style={text_th} >URL</div>
                <div className='col-6' style={text_td}><a href={v.url} >Click</a></div>
                </>
                }
            
                {
                    v.duration==""?<></>:<> <div className='col-6' style={text_th} >Duration</div>
                    <div className='col-3' style={text_td}>{v.duration}</div></>
                }
               
                </div>
              </div>
           

      ))
      }
        
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


      
        
        
      </div>
      
    
    </div>
            </div>
        </div>

  

<div>

{
  manager_list_risponse.length>0?<>
  <div class="table-responsive">
    <h2  >History Manager</h2>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Status</th>
                <th scope="col">Msg</th>
                <th scope="col">Date & Time</th>
                <th scope="col">Type</th>
            </tr>
        </thead>
        <tbody>
            {
              manager_list_risponse.map((v,ii)=>(
                <tr>
                <th scope="row">{v.i}</th>
                <td>{v.s=="Reminder"?<> {getNamesByValue(status_list,v.s)}  ({v.date}) </>:getNamesByValue(status_list,v.s)}</td>
                <td>{v.msg}</td>
                <td>{v.date} {v.at}</td>
                <td>{v.type=="true"?"Incorrect":"correct"}</td>
            </tr>
              ))
            }
            
        </tbody>
    </table>
</div>
</>:<></>
}


    <h2>History</h2>
  
    <div class="table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Status</th>
                <th scope="col">Msg</th>
                <th scope="col">At</th>
            </tr>
        </thead>
        <tbody>
            {
              list_risponse.map((v,ii)=>(
                <tr>
                <th scope="row">{v.i}</th>
                <td>{v.s=="Reminder"?<> {getNamesByValue(status_list,v.s)}  ({v.date}) </>:getNamesByValue(status_list,v.s)}</td>
                <td>{v.msg}</td>
                <td>{v.at}</td>
            </tr>
              ))
            }
            
        </tbody>
    </table>
</div>



</div>



        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}