
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";



const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

  const getInq = async (id)=> {
    const data = new FormData();
    data.append('id',id);
            axios({
              method: 'post',
              url: '/api_lock/agent/get_inq.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)
                console.log(id)
               ;
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getPhone = async (phone,id)=> {
    const data = new FormData();
        data.append("phone",phone)
        data.append("id",id)
            axios({
              method: 'post',
              url: '/api_lock/agent/get_phone_detail.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {

                const a=s.data[0].msg
                var x="/view_inquiry?id="

                if(a=="new")
                {
                  alert(s.data[0].dis)


                }else if(a=="old")
                {
                  alert(s.data[0].dis)
                }else if(a=="dashboard"){
                  alert(s.data[0].dis)

                }else if(a=="view"){

                  window.location.replace(x+s.data[0].inq)

                }

                
                 
                
              
                
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

 




  const View_time_update = async (inquiry_id,agent_id)=> {

    const data = new FormData();
    data.append('inquiry_id',inquiry_id);
    data.append('agent_id',agent_id);
        
            axios({
              method: 'post',
              url: '/api_lock/agent/view_time_update.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => { 
                console.log(s.data)
                getInq(JSON.parse(user)[0].id)

             
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  const [alltype,setAllType]=useState([])
  const [allwebsite,setAllwebsite]=useState([])


  const [phoneSelect,setSelectPhone]=useState("")
  const [typeSelect,setSelectType]=useState("")
  const [webSelect,setSelectWeb]=useState("")
  const [emailSelect,setSelectEmail]=useState("")
 
  const getAllType = async ()=> {
    const data = new FormData();
    data.append('id','');
            axios({
              method: 'post',
              url: '/api_lock/web/listtype.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setAllType(s.data)
            })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

  const getAllWebsite = async ()=> {
    const data = new FormData();
    data.append('id','');
            axios({
              method: 'post',
              url: '/api_lock/web/list.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setAllwebsite(s.data)
            })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



          const add_inq_by_Agent = async (name)=> {
            const data = new FormData();
            data.append('phone',phoneSelect);
            data.append('email',emailSelect);
            data.append('website',webSelect);
            data.append('type',typeSelect);
            data.append('name',name);

                    axios({
                      method: 'post',
                      url: '/api_lock/agent/add_inq_by_agent.php',
                      data: data,
                      headers: {'Content-Type': 'multipart/form-data'},
                    })
                      .then(s => {
                        const d=s.data;
                        console.log("return",d);

                        if(d[0].msg=='dup_add')
                        {
                            alert("An inquiry already exists with the same phone number and brand.")
                        }else{

                            add_inq_by_Agent_self(d[0].inq_id,JSON.parse(user)[0].id,JSON.parse(user)[0].team,JSON.parse(user)[0].id)
                        }
                        
                        
                        
                       
                 
                      })
                      .catch(s => {
                        console.log('here catch =>>', s);
                      });
                  };

          const add_inq_by_Agent_self = async (inquiry_id,admin_id,team_id,agent_id)=> {
            
            const data = new FormData();
            data.append('inquiry_id',inquiry_id);
            data.append('admin_id',admin_id);
            data.append('team_id',team_id);
            data.append('agent_id',agent_id);
            

                    axios({
                      method: 'post',
                      url: '/api_lock/agent/add_agent_inq_myself.php',
                      data: data,
                      headers: {'Content-Type': 'multipart/form-data'},
                    })
                      .then(s => {
                        const d=s.data;
                        console.log("return",d);
                        alert("Welcome to this new inquiry; it has been added to your dashboard.")
                        
        
                        window.location.replace("/agent_main")

                        
                       
                 
                      })
                      .catch(s => {
                        console.log('here catch =>>', s);
                      });
                  };


  const [list,setList]=useState([])

  const [Reminder,setReminder]=useState([])

 
  

  const [user,setUser]=useState(null);

  useEffect(() => {
    const a=localStorage.getItem("Agent");
    if(a==null)
    {
     // window.location.replace("/login")
    }else{
        getAllWebsite();
        getAllType();
      setUser(a)
      //getInq(JSON.parse(a)[0].id);
      getInq_Reminder(JSON.parse(a)[0].id)
     
    }


  
  
  }, []);

  const getInq_Reminder = async (id)=> {
    const data = new FormData();
    data.append('agent_id',id);
    
            axios({
              method: 'post',
              url: '/api_lock/agent/reminder.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                
                const se=s.data;
                se.sort((a, b) => {
                  return new Date(a.date_reminder) - new Date(b.date_reminder);
              });
                setReminder(se)
              
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


          function getRemainingDays(targetDate) {
            const currentDate = new Date();
            const remainingTime = new Date(targetDate) - currentDate;
            
            // Convert remaining time from milliseconds to days
            const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
            
            return remainingDays;
        }




  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          
        <div className="card">
  <div className="card-body">
    <div className='row'>
      <div className='col-12'>
      <h4 style={{color :"blue"}}>Duplicate Inquiry Alert </h4>
    
      
    

        
          <div className="row">
            <div className='col-3'>
              <label htmlFor="phone" className="form-label">Phone* (Example : 44778899110)</label>
              <input type="number" placeholder='Phone Number' className="form-control" id="phone" name="phone" value={phoneSelect} onChange={(v)=>{ setSelectPhone(v.target.value) }}  />
            </div>

            <div className='col-3'>
              <label htmlFor="type" className="form-label">Select Source Type*</label>
              <select className="form-control"  value={typeSelect} onChange={(v)=>{ setSelectType(v.target.value) }}  >
              <option value={''}>Select Source Type </option>
                {
                    alltype.map((v,i)=>(
                        <option value={v.name}>{v.show}</option>
                    ))
                }
              </select>
            
            </div>

            <div className='col-3'>
              <label htmlFor="web" className="form-label">Select Web *</label>
              <select className="form-control" value={webSelect} onChange={(v)=>{ setSelectWeb(v.target.value) }} >
                <option value={''}>Select Website </option>
                {
                    allwebsite.map((v,i)=>(
                        <option>{v.web}</option>
                    ))
                }
              </select>
            </div>
            <div className='col-3'>
              <label htmlFor="e" className="form-label">Email (Optional)</label>
              <input type="url" className="form-control" id="e" name="e" value={emailSelect} onChange={(v)=>{ setSelectEmail(v.target.value) }}  />
            </div>

           

            

          </div>

          <button type="button"  className="btn btn-primary mt-3" onClick={()=>{

if(phoneSelect.length<11 || phoneSelect.length>12)
{
    alert("Enter a valid phone number first")
}else if(typeSelect.length<1)
    {
        alert("Select the correct type")
    }else if(webSelect.length<1)
        {
            alert("Select the correct website")
        }else{
            add_inq_by_Agent("Agent :"+JSON.parse(user)[0].name);
        }

    
          }
           
          } >Submit</button>
    
      </div>
    </div>
  </div>
</div>

         
   


   
        

  


    <div className="card" style={{backgroundColor :"#753642"}}>
          <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
<h4 style={{color :"white"}}>Reminder Alert </h4>
   
   
   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info">
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  <th
                    className="sorting"
          
                  >
                    Inquiry
                  </th>
                  <th
                    className="sorting"
                   
          
                  >
                    Received
                  </th>
                  <th
                    className="sorting">
                    Folllow Up
                  </th>
                 
                  <th className="sorting">Date</th>
                  <th className="sorting">Remaing Days</th>
                  <th className="sorting">Account</th>
                  <th
                    className="sorting">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
      {
        Reminder.map((v)=>(   
        

              <>
{
              
              
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.web}</td>
                  <td>{v.date_portal} {v.date_time}</td>
                  <td>{v.sender_time}</td>
                  <td>{v.agent_status}</td>
                  <td>{v.date_reminder}</td>
                  <td>
                    {  getRemainingDays(v.date_reminder)==0?"Today":getRemainingDays(v.date_reminder)<0?<div style={{color:'red'}}>Late {getRemainingDays(v.date_reminder)} Days</div>:<div style={{color:"green"}}>{getRemainingDays(v.date_reminder)} Days</div>}</td>
                  <td>
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <>{v.url}</>
      
        }
      >
        <Button>{v.sender_type}</Button>
      </HtmlTooltip>
      </td>
      
     <td>
      <a target='black_' href={"/view_inquiry?id="+v.id}>
        <button 
        className="btn btn-warning m-r-5">View</button>
                 
                 </a>
                  </td>
                </tr>
              }
              
                
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >

      </div>
      
    
    </div>


        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}