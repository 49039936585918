
import { BrowserRouter, Routes, Route } from "react-router-dom"
import React, { useEffect } from 'react';
import BackgroundTask from './pages/BackgroundTask';

import NoPage from "./pages/NoPage";
import Nav from "./pages/nav";

// common
import Login from "./pages/login";
import MyLogin from "./pages/mypage"; 
import PIN from "./pages/pin";

// Admin
import Admin_main from "./admin/admin_main";
import Get_date_to_date from "./admin/get_date_to_date";
import Files from "./admin/files";
import FilesBlast from "./admin/filesblast";
import Team_main from "./admin/team_main";
import Log_main from "./admin/log";
import Users from "./admin/user";
import New_users from "./admin/new_user";
import Edit_user from "./admin/edit_user";
import State_users from "./admin/user_state";
import All_get_Trash from "./admin/get_all_trash";
import Team_to_agent_pending from "./admin/team_to_agent_pending";
import View_pending_admin from "./admin/view_pending_admin";
import Waiting_response_admin from "./admin/waiting_response";
import Reminder_list from "./admin/reminder_list";
import Pinview from "./admin/pinview";
import FollowUPAdmin from "./admin/followup";
import View_inquiry_Admin from "./admin/view_inquiry_Admin";
import Dup from "./admin/dup";
import Web from "./admin/webs";
import Voicemail_admin from "./admin/voicemail";
import Counter_view from "./admin/counter_view";
import Agent_report_list from "./admin/agent_report_list";
import Dev from "./admin/dev";
import Client_discount from "./admin/discount_sub";
import Contact_us_all from "./admin/contact_all";
import ComplaintConvert from "./admin/contact_to_complaint";
import ComplaintNew from "./admin/complain_new";
import ComplaintList from "./admin/newcomplaint_list";
import Convert_complaint_list from "./admin/convert_complaint_list";
import Show_complaint from "./admin/show_complaint";

import Report_ads from "./admin/get_date_to_date_ads_report";
import Admin_main_types from "./admin/admin_main_types";
import All_status from "./admin/all_status";


  //  {/* Agent URLs */}
import Agent_main from "./agent/main";
import View_inquiry from "./agent/inquiry_view_full";
import Wait_for_response from "./agent/wait_for_response";
import Report from "./agent/report";
import Reminder from "./agent/reminder";
import Follow_agent from "./agent/follow_agent";
import Status_View from "./agent/status_view";
import Complaint_list from "./agent/complaint_list";
import Complaint_Show_agent from "./agent/complaint_show";
import Policy from "./pages/policy";
import Voicemail from "./agent/voicemail";
import Add_inq_agent from "./agent/add_inq_agent";

  //  {/* Manager URLs */}
import Manager_main from "./manager/main";
import AllManagerTeam from "./manager/all_team";
import AllTeamInq from "./manager/team_inq";
import AgentInq from "./manager/agent_inq";
import Pending_view from "./manager/pending_view";
import Wait_for_response_Manager from "./manager/waiting_response";
import View_inquiry_manager from "./manager/inquiry_view";
import Reminder_manager from "./manager/reminder_list";
import Agent_report_manager from "./manager/agent_report";
import Follow_manager from "./manager/follow_manager";
import Voicemail_manager from "./manager/voicemail";
import All_complaint_show from "./manager/all_complaints_show";
import Show_complaint_manager from "./manager/complaint_show";
import Show_manager_res from "./manager/manager_response";

import Status_view_manager from "./manager/status_view_manager";
import DupManager from "./manager/dup";

export default function IconLabelButtons() {

  
     
  return (<>
    <BackgroundTask />
    <BrowserRouter basename="/">
    <Routes>
      
      <Route  >
        {/* Common URLs */}
        <Route index element={<> <Login />  </>} />
        {/* <Route path="/csv" element={<><Nav /> <NoPage /></>} /> */}
        <Route path="/mylogin" element={<MyLogin />} />
        <Route path="/pin" element={<PIN />} />
        <Route path="*" element={<> <Login /> </>} />

        {/* Admin */}
        <Route path="/admin_main" element={<><Admin_main /></>} />
        <Route path="/inq_date_to_date" element={<><Get_date_to_date /></>} />
        <Route path="/files" element={<><Files /></>} />
        <Route path="/filesblast" element={<><FilesBlast /></>} />
        <Route path="/team" element={<><Team_main /></>} />
        <Route path="/log" element={<><Log_main /></>} />
        <Route path="/users" element={<><Users /></>} />
        <Route path="/newusers" element={<><New_users /></>} />
        <Route path="/edit_user" element={<><Edit_user /></>} />
        <Route path="/userinfo" element={<><State_users /></>} />
        <Route path="/alltrash" element={<><All_get_Trash /></>} />

        <Route path="/team_to_agent_pending" element={<><Team_to_agent_pending /></>} />
        <Route path="/view_pending_admin" element={<><View_pending_admin /></>} />
        <Route path="/waiting_response_admin" element={<><Waiting_response_admin /></>} />
        <Route path="/reminder_list" element={<><Reminder_list /></>} />
        <Route path="/pinview" element={<><Pinview /></>} />
        <Route path="/follow_up_admin" element={<><FollowUPAdmin /></>} />
        <Route path="/view_inquiry_Admin" element={<><View_inquiry_Admin /></>} />
        
        <Route path="/dup" element={<><Dup /></>} />
        <Route path="/web" element={<><Web /></>} />
        <Route path="/voicemail" element={<><Voicemail_admin /></>} />
        <Route path="/counter_view" element={<><Counter_view /></>} />
        <Route path="/agent_report_list" element={<><Agent_report_list /></>} />
        <Route path="/dev" element={<><Dev /></>} />
        <Route path="/client_discount" element={<><Client_discount /></>} />
        <Route path="/contact_us_all" element={<><Contact_us_all /></>} />
        <Route path="/contact_to_complaint" element={<><ComplaintConvert /></>} />
        <Route path="/newcomplaint" element={<><ComplaintNew /></>} />
        <Route path="/complaintlist" element={<><ComplaintList /></>} />
        <Route path="/convert_complaint_list" element={<><Convert_complaint_list /></>} />
        <Route path="/show_complaint" element={<><Show_complaint /></>} />
        <Route path="/report_ads" element={<><Report_ads /></>} />
        <Route path="/admin_main_types" element={<><Admin_main_types /></>} />

        <Route path="/all_status_admin" element={<><All_status /></>} />
        
        {/* Manager URLs */}
        <Route path="/manager_main" element={<><Manager_main /></>} />
        <Route path="/manager_team" element={<><AllManagerTeam /></>} />
        <Route path="/team_inq" element={<><AllTeamInq /></>} />
        <Route path="/agent_inq" element={<><AgentInq /></>} />
        <Route path="/pending_view" element={<><Pending_view /></>} />
        <Route path="/wait_for_response_manager" element={<><Wait_for_response_Manager /></>} />
        <Route path="/view_inquiry_manager" element={<><View_inquiry_manager /></>} />
        <Route path="/reminder_manager" element={<><Reminder_manager /></>} />
        <Route path="/follow_manager" element={<><Follow_manager /></>} />
        <Route path="/agent_report_manager" element={<><Agent_report_manager /></>} />
        <Route path="/voicemail_manager" element={<><Voicemail_manager /></>} />
        <Route path="/all_complaint_show" element={<><All_complaint_show /></>} />
        <Route path="/show_complaint_manager" element={<><Show_complaint_manager /></>} />
        <Route path="/res_manager" element={<><Show_manager_res /></>} />
        <Route path="/status_view_manager" element={<><Status_view_manager /></>} />
        <Route path="/dup_manager" element={<><DupManager /></>} />

        {/* Agent URLs */}
        <Route path="/agent_main" element={<><Agent_main /></>} />
        <Route path="/view_inquiry" element={<><View_inquiry /></>} />
        <Route path="/wait_for_response" element={<><Wait_for_response /></>} />
        <Route path="/report" element={<><Report /></>} />
        <Route path="/reminder" element={<><Reminder /></>} />
        <Route path="/follow_agent" element={<><Follow_agent /></>} />
        <Route path="/status_view" element={<><Status_View /></>} />
        <Route path="/Voicemail_agent" element={<><Voicemail /></>} />
        <Route path="/add_inq_agent" element={<><Add_inq_agent /></>} />
        <Route path="/complaint_list_agent" element={<><Complaint_list /></>} />
        <Route path="/announcements" element={<><Policy /></>} />
        <Route path="/complaint_show_agent" element={<><Complaint_Show_agent /></>} />
        
      </Route>
    </Routes>    
  </BrowserRouter>
  </>
  );
}